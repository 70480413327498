.switch-button-label {
  float: left;
  font-size: 14px;
  cursor: pointer;
  line-height: 16px;
  font-weight: bold;
  font-style: normal;
}

.product-order-off, .switch-button-label.off {
  color: #F26953;
}

.product-order-on, .switch-button-label.on {
  color: #91C950;
}

.product-order-pending {
  color: #ffb100;
}

.switch-button-background {
  float: left;
  position: relative;
  background: #ccc;
  border: 1px solid #aaa;
  margin: 1px 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
}

.switch-button-button {
  position: absolute;
  left: -1px;
  top : -1px;
  background: #FAFAFA;
  border: 1px solid #aaa;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.switch-wrapper {
  display: inline-block;
  position: relative;
  top: 4px;
}

.ps-container .ps-scrollbar-x-rail, .ps-container .ps-scrollbar-y-rail {
  opacity: 0.6;
  filter: alpha(opacity = 60);
  background-color: #eee;
}

.onoffswitch {
  position: relative;
  width: 55px;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select: none;
}

.onoffswitch-checkbox, .form-horizontal .onoffswitch-checkbox
{
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 11px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #5BCF44;
  color: #FFFFFF;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #DE6464;
  color: #FFFFFF;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 6px;
  height: 6px;
  margin: 6px;
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  position: absolute;
  right: 33px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}

div.container-switch {
  float: left;
  height: 30px;
  padding: 4px 0;
}

div.container-switch.warning .onoffswitch-inner:before {
  background-color: #ffb100;
  color: #ffffff;
}

span.status-in-process.label.label-warning {
  margin: 7px 0;
  display: inline-block;
}

/* jGrowl styles. */
.jGrowl {
  z-index: 9999;
  color: #fff;
  font-size: 12px;
}

.ie6 {
  position: absolute;
}

.jGrowl {
  position: absolute;
}

body > .jGrowl {
  position: fixed;
}

.jGrowl.top-left {
  left: 0;
  top: 0;
}

.jGrowl.top-right {
  right: 20px;
  top: 50px;
}

.jGrowl.bottom-left {
  left: 0;
  bottom: 0;
}

.jGrowl.bottom-right {
  right: 0;
  bottom: 0;
}

.jGrowl.center {
  top: 0;
  width: 50%;
  left: 25%;
}

.center .jGrowl-notification, .center .jGrowl-closer {
  margin-left: auto;
  margin-right: auto;
}

.jGrowl .jGrowl-notification {
  border: none;
  color: #fff;
  opacity: .95;
  filter: alpha(Opacity=95);
  zoom: 1;
  width: 194px;
  padding: 8px 12px;
  margin-top: 10px;
  text-align: left;
  display: none;
  background: #448fb2 -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.025)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.025)), color-stop(0.75, rgba(255, 255, 255, 0.025)), color-stop(0.75, transparent), to(transparent)) repeat;
  -webkit-background-size: 10px 10px;
  -moz-background-size: 10px 10px;
  -o-background-size: 10px 10px;
  background-size: 10px 10px;
  -webkit-animation: growl-stripes 1s linear infinite;
  -moz-animation: growl-stripes 1s linear infinite;
  -o-animation: growl-stripes 1s linear infinite;
  animation: growl-stripes 1s linear infinite;
}

.jGrowl-notification.growl-error {
  background-color: #C95454;
}

.jGrowl-notification.growl-success {
  background-color: #49a449;
}

.jGrowl-notification.growl-warning {
  background-color: #D17E3D;
}

.jGrowl .jGrowl-notification .jGrowl-header {
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
}

.jGrowl .jGrowl-notification .jGrowl-close {
  z-index: 99;
  float: right;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.jGrowl .jGrowl-closer {
  padding: 3px 0;
  cursor: pointer;
  font-size: 11px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  background: #454545;
  color: #ffffff;
  width: 218px;
}

@media print {
  .jGrowl {
    display: none;
  }
}

/* other styles */
div.modal-body div.checkbox label {
  margin-left: 0;
}

div.clear {
  clear: both!important;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.companyListActionColumn {
  width: 210px;
}

div#next-executions {
  max-height: 100px;
  overflow: auto;
  background-color: #f8f8f8;
  border: 1px solid #e7e7e7;
  padding: 10px;
}

body.sidebar-collapse div.fix-filters {
  position: fixed;
  top: 100px;
  z-index: 1;
  padding: 0;
  margin: 0;
}

body div.fix-filters {
  position: fixed;
  top: 100px;
  z-index: 1;
  margin: 0 0 0 -15px;
  padding: 0 215px 0 0;
}

.sonata-ba-view-container .width-inherit-important {
  width: inherit!important;
}

div.solution .checkbox,
div.solution .radio {
  margin: 0;
}

div.solution .label-hidden .control-label__text,
table.service .label-hidden .control-label__text {
  display: none;
}

div[id^='sonata-ba-field-container-'][id$='_services'] label.control-label,
div[id^='sonata-ba-field-container-'][id$='_solutions'] label.control-label {
  display: none;
}

div.service {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 5px;
}

div.service label.control-label {
  display: inline-block!important;
}

table.service label {
  margin: 0;
  cursor: pointer;
}

table.service p.boundary_does_not_apply {
  margin-top: 10px;
}

.width-10percent {
  width: 10%;
}

.width-30percent {
  width: 30%;
}

.company_status_show {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.company_status_show ul {
  list-style: none;
  padding: 0;
}

.invoiceable_company_name_show {
  background-color: #b7e1cd;
  margin: -8px -8px;
  padding: 8px 8px;
}

i.company_is_registration_type_organic_icon,
i.company_is_invoiceable_icon {
  color: #5cb85c;
  margin-top: 4px;
}

.company_services_show {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.ticket-note.ticket-note-color-yellow .direct-chat-text {
  background: #ffefbb;
  border-color: #ffefbb;
}

.ticket-note.ticket-note-color-yellow .direct-chat-text:after,
.ticket-note.ticket-note-color-yellow .direct-chat-text:before {
  border-right-color: #ffefbb;
}

.ticket-note.ticket-note-color-purple .direct-chat-text {
  background: #e5c7ec;
  border-color: #e5c7ec;
}

.ticket-note.ticket-note-color-purple .direct-chat-text:after,
.ticket-note.ticket-note-color-purple .direct-chat-text:before {
  border-right-color: #e5c7ec;
}

.ticket-note.ticket-note-color-blue .direct-chat-text {
  background: #c7d6ec;
  border-color: #c7d6ec;
}

.ticket-note.ticket-note-color-blue .direct-chat-text:after,
.ticket-note.ticket-note-color-blue .direct-chat-text:before {
  border-right-color: #c7d6ec;
}

.ticket-note.ticket-note-color-green .direct-chat-text {
  background: #e5ecc7;
  border-color: #e5ecc7;
}

.ticket-note.ticket-note-color-green .direct-chat-text:after,
.ticket-note.ticket-note-color-green .direct-chat-text:before {
  border-right-color: #e5ecc7;
}

.ticket-note.ticket-note-color-red .direct-chat-text {
  background: #ecc9c9;
  border-color: #ecc9c9;
}

.ticket-note.ticket-note-color-red .direct-chat-text:after,
.ticket-note.ticket-note-color-red .direct-chat-text:before {
  border-right-color: #ecc9c9;
}

.direct-chat-support .right> .direct-chat-text {
  background: #eeeeee;
  border-color: #eeeeee;
}

.direct-chat-support .right>.direct-chat-text:after,
.direct-chat-support .right>.direct-chat-text:before {
  border-left-color:#eeeeee;
}

th.min-width-small {
  min-width: 110px;
}

th.min-width-datetime {
  min-width: 170px;
}

th.min-width-dateinterval {
  min-width: 130px;
}

.table>tbody>tr.strong-warning>td,
.table>tbody>tr.strong-warning>th,
.table>tbody>tr>td.strong-warning,
.table>tbody>tr>th.strong-warning,
.table>tfoot>tr.strong-warning>td,
.table>tfoot>tr.strong-warning>th,
.table>tfoot>tr>td.strong-warning,
.table>tfoot>tr>th.strong-warning,
.table>thead>tr.strong-warning>td,
.table>thead>tr.strong-warning>th,
.table>thead>tr>td.strong-warning,
.table>thead>tr>th.strong-warning {
  background-color: #fbda3b;
}

.table>tbody>tr.strong-warning:hover>td,
.table>tbody>tr.strong-warning:hover>th,
.table>tbody>tr:hover>td.strong-warning,
.table>tbody>tr:hover>th.strong-warning,
.table>tfoot>tr.strong-warning:hover>td,
.table>tfoot>tr.strong-warning:hover>th,
.table>tfoot>tr:hover>td.strong-warning,
.table>tfoot>tr:hover>th.strong-warning,
.table>thead>tr.strong-warning:hover>td,
.table>thead>tr.strong-warning:hover>th,
.table>thead>tr:hover>td.strong-warning,
.table>thead>tr:hover>th.strong-warning {
  background-color: #ffd300;
}

.margin-0 {
  margin: 0 !important;
}

.text-disabled {
  text-decoration: line-through;
  color: #676767;
}

div.direct-chat-msg:target div.direct-chat-text {
  box-shadow: 1px 1px 8px 2px #3c8dbc;
}

table.invoice-items {
  margin: -8px 0 0 0;
}
